import instagram from "../../Assets/instagram.png"
import facebook from "../../Assets/facebook.png"
import { Link } from "react-router-dom";
import './footer.css'
import marionlogo2 from "../../Assets/marionlogo2.jpg"
import motif from "../../Assets/motif.jpg"


function Footer (){
    return(
            <div className="footer-conteneur">
                <div className="footer-img"><img src={motif} alt="onglerie tourcoing"></img></div>
                <div className="header-logo">
                <Link to="/" className="link"> <img src={marionlogo2} alt="estheticienne tourcoing"/></Link>
                </div>
                <div className="coordonnee">
                    <div className="tel">
                        06.64.59.90.13
                    </div>
                    <div className="adress">
                    32 rue de cronstadt, 
                    </div>
                    <div className="ville">
                        59200 Tourcoing 
                    </div>        
                </div>
                <div className="cartes">
                <iframe title="cartes" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.436492646329!2d3.164507576078136!3d50.730392667168545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32f258478cedb%3A0x451de8a743187c64!2s32%20Rue%20de%20Cronstadt%2C%2059200%20Tourcoing!5e0!3m2!1sfr!2sfr!4v1703873903310!5m2!1sfr!2sfr" width="200" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="footer-liens">
                    <a href ="https://www.instagram.com/majestueusemane/"><img src ={instagram}alt= "esthétique Tourcoing"></img></a>
                    <a href ="https://www.facebook.com/majestueusemane?locale=fr_FR"><img src={facebook}alt="massage, beauté à Tourcoing"></img></a>
                </div>
                <div className="footer-img"><img src={motif} alt="onglerie tourcoing"></img></div>
            </div>
    )
}
export default Footer